import { Component, EventEmitter, OnInit, Output, inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
	CropperDialogResult,
	GeneralService,
} from "@sportyano/core/services/generals/general.service";
import { ImageCroppedEvent } from "ngx-image-cropper";

export type CropperDialogData = {
	image: File;
	width: number;
	height: number;
};

@Component({
	selector: "app-image-cropper-tool",
	templateUrl: "./image-cropper-tool.component.html",
	styleUrl: "./image-cropper-tool.component.scss",
})
export class ImageCropperToolComponent {
	constructor(private generalService: GeneralService) {}
	data: CropperDialogData = inject(MAT_DIALOG_DATA);
	croppedImage: CropperDialogResult;

	imageCropped(event: ImageCroppedEvent): void {
		const { blob, objectUrl } = event;
		if (blob && objectUrl) {
			this.croppedImage = { blob: blob, imageUrl: objectUrl };
		}
	}

	sendResult() {
		this.generalService.setCroppedImageState(this.croppedImage);
	}

	clostDialog() {}
}
