<mat-dialog-content>
	<mat-dialog-content>
		<image-cropper [cropperMinWidth]="1440" [cropperMinHeight]="440" [imageFile]="data.image"
			[maintainAspectRatio]="true" format="jpeg" [aspectRatio]="1440 / 440" (imageCropped)="imageCropped($event)">

		</image-cropper>
	</mat-dialog-content>

	<mat-dialog-actions>
		<button mat-button mat-dialog-close (click)="clostDialog()">No</button>
		<button mat-button mat-dialog-close cdkFocusInitial (click)="sendResult()">Ok</button>
	</mat-dialog-actions>

</mat-dialog-content>