<app-head-advanced-filteration (exportFilter)="exportFilter($event)" />
<div class="rounded-md">
	<div class="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-between items-center">
		@for (item of playersList; track $index) {
		<app-cart-profile (unlockProfile)="unlocakProfile = true" [playerData]="item" [showShortListButton]="false"
			[showUnlock]="false" />
		@if (($index + 1) % 12 === 0 && !$last) {
		<ng-container>
			<div class="col-span-4">
				<app-advertisement-card customWidth="100%" [adImage]="'assets/images/ads/Adv.png'" />
			</div>
		</ng-container>
		}
		}
	</div>
</div>
<unlock-profile-dialog [unlockProfileModel]="unlocakProfile" />
