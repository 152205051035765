import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MaterialImportsModule } from "@sportyano/shared/ui-modules/material-imports";
import { PrimeModule } from "@sportyano/shared/ui-modules/primeng-imports";
import {
	CartAcademyComponent,
	CartPlayerComponent,
	CartProfileComponent,
	CartReviewComponent,
	CartSkillsComponent,
	CartVideoComponent,
	HeadComponent,
	NavTabsComponent,
	RateCartComponent,
	SingleTrainingProgramComponent,
	ChangeMobileComponent,
	PagginationComponent,
	ShareModalComponent,
	ArabicOnlyDirective,
	EnglishOnlyDirective,
	AddPostPopUpComponent,
	AdvancedSideFilterationComponent,
	AdvertisementCardComponent,
	FilterationOptionsComponent,
	HeadAdvancedFilterationComponent,
	HeadSectionComponent,
	LocationCardComponent,
	MessagingDialogComponent,
	NewNewsCardComponent,
	NewsCardComponent,
	ProgramScheduleComponent,
	SportsPlaygroundViewComponent,
	SubscriptionComponent,
	SuccessPopUpComponent,
	UnlockProfileDialogComponent,
	ClickOutsideDirective,
	DynamicDialogComponent,
	LoginPopupComponent,
	CustomCalendarComponent,
} from "@sportyano/shared/components";
import { RecruitmentComponent } from "./components/recruitment/recruitment.component";
import { ImageCropperToolComponent } from "./components/image-cropper-tool/image-cropper-tool.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { PlayersModule } from "@sportyano/main-site/players/players.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { FileSizePipe } from "@sportyano/shared/pipes/file-size.pipe";
import { NumericOnlyDirective } from "@sportyano/shared/directives/numeric-only.directive";
import { CalendarSchedulerComponent } from "@sportyano/shared/components/calendar-scheduler/calender-scheduler.component";

const sharedComponents = [
	HeadComponent,
	CartAcademyComponent,
	CartPlayerComponent,
	CartVideoComponent,
	SingleTrainingProgramComponent,
	RateCartComponent,
	CartReviewComponent,
	CartSkillsComponent,
	SubscriptionComponent,
	NavTabsComponent,
	SubscriptionComponent,
	CartSkillsComponent,
	NewsCardComponent,
	SuccessPopUpComponent,
	MessagingDialogComponent,
	AddPostPopUpComponent,
	HeadSectionComponent,
	FilterationOptionsComponent,
	AdvancedSideFilterationComponent,
	HeadAdvancedFilterationComponent,
	NewNewsCardComponent,
	LocationCardComponent,
	ProgramScheduleComponent,
	SportsPlaygroundViewComponent,
	UnlockProfileDialogComponent,
	DynamicDialogComponent,
	ChangeMobileComponent,
	LoginPopupComponent,
	RecruitmentComponent,
	CustomCalendarComponent,
];

const sharedDirectives = [
	NumericOnlyDirective,
	EnglishOnlyDirective,
	ArabicOnlyDirective,
	ClickOutsideDirective,
];

@NgModule({
	declarations: [
		// components
		sharedComponents,

		// directives
		sharedDirectives,
		LoginPopupComponent,
		ImageCropperToolComponent,
	],
	imports: [
		PrimeModule,
		MaterialImportsModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		ImageCropperModule,
		NgxSpinnerModule,
		FileSizePipe,
		PagginationComponent,
		ShareModalComponent,
		CartProfileComponent,
		AdvertisementCardComponent,
		CalendarSchedulerComponent
	],
	exports: [
		// components
		sharedComponents,

		// directives
		sharedDirectives,

		// modules
		CommonModule,
		PrimeModule,
		MaterialImportsModule,
		TranslateModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		NgxSpinnerModule,
		PagginationComponent,
		ShareModalComponent,
		CartProfileComponent,
		AdvertisementCardComponent
	],
})
export class SharedModule {}
